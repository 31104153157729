import React, { memo } from "react";
import SimpleModelViewer from "../common/simpleModelViewer";

function ProductPreview3D({ src, arImg, w, h }) {
  return (
    <SimpleModelViewer src={src} arIOSSupported={false} arImg={arImg} handleiOSARClick={() => {}} w={w} h={h} />
  );
}

export default ProductPreview3D;
