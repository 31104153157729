const isPassWordStrong = (password) => {
  if (password.length < 8) {
    return false;
  }
  if (!password.match(/[a-z]+/)) {
    return false;
  }
  if (!password.match(/[A-Z]+/)) {
    return false;
  }
  if (!password.match(/[0-9]+/)) {
    return false;
  }

  return true;
};

const hasWhiteSpace = (password) => {
  return /\s/g.test(password);
};

export { isPassWordStrong, hasWhiteSpace };
