/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/self-closing-comp */
import React, { memo, useState, useRef } from 'react';
import languageService from '../../services/language/languageService';

const baseFlagUrl = "https://flagicons.lipis.dev/flags/4x3/";

const mapFlagsIso = {
  en: 'us',
  nl: 'nl',
  es: 'es',
};

// eslint-disable-next-line react/prop-types
const HeaderLanguagePicker = ({ selectedLanguage, onLanguageChange }) => {
  const [visible, setVisibility] = useState(false);

  function handleClick() {
    setVisibility(!visible);
  }
  function handleChangeLanguage(iso) {
    onLanguageChange(iso);
    handleClick();
  }
  return (
    <div className="w-26" onClick={handleClick} style={{ zIndex: '999' }}>
      <div className="relative mt-1">
        <button
          className="relative w-full md:w-32 py-2 pl-0 pr-0 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm"
          id="headlessui-listbox-button-1"
          type="button"
          aria-haspopup="true"
          aria-expanded="true"
          aria-controls="headlessui-listbox-options-5"
        >
          <div className="flex items-center uppercase">
            <span className="h-full flex flex-row items-center truncate pl-2 pr-1">
              <div
                className="h-full"
                style={{
                  display: "inline-block",
                  maxWidth: "100%",
                  overflow: "hidden",
                  position: "relative",
                  boxSizing: "border-box",
                  margin: 0,
                }}
              >
                <div
                  style={{
                    boxSizing: "border-box",
                    display: "block",
                    maxWidth: "100%",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      display: "block",
                      margin: 0,
                      border: "none",
                      padding: 0,
                    }}
                    alt=""
                    aria-hidden="true"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
                  />
                </div>
                <img
                  alt="usa flag"
                  src={`${baseFlagUrl + mapFlagsIso[selectedLanguage]}.svg`}
                  decoding="async"
                  data-nimg="intrinsic"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    boxSizing: "border-box",
                    padding: 0,
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: 0,
                    height: 0,
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                  }}
                />
              </div>
            </span>
            {languageService.languages.find(lang => lang.iso === selectedLanguage).title}
          </div>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="w-5 h-5 text-gray-400"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
        <ul
          className={`absolute left-0 w-28 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10 ${visible ? ' ' : 'hidden'}`}
          aria-labelledby="headlessui-listbox-button-1"
          aria-orientation="vertical"
          id="headlessui-listbox-options-5"
          role="listbox"
          tabIndex={0}
          aria-activedescendant="headlessui-listbox-option-6"
          style={{
            top: '2.7rem',
            zIndex: '1400',
          }}
        >
          {languageService.languages.map(({ iso, title }) => {
            return <li key={title} className={`cursor-pointer select-none relative py-2 pl-7 pr-4 text-yellow-900 ${selectedLanguage === iso ? 'bg-yellow-100' : ''}`} id="headlessui-listbox-option-6" role="option" tabIndex={-1} aria-selected="true" onClick={() => handleChangeLanguage(iso)}>
              <div className="flex gap-2 uppercase">
                <span className="flex justify-around px-2">
                  <div
                    style={{
                      display: "inline-block",
                      maxWidth: "100%",
                      overflow: "hidden",
                      position: "relative",
                      boxSizing: "border-box",
                      margin: 0,
                    }}
                  >
                    <div
                      className="w-3"
                      style={{
                        boxSizing: "border-box",
                        display: "block",
                      }}
                    >
                      <img
                        alt=""
                        aria-hidden="true"
                        src={`${baseFlagUrl + mapFlagsIso.en}.svg`}
                        className="w-full"
                        style={{
                          maxWidth: "100%",
                          display: "block",
                          margin: 0,
                          border: "none",
                          padding: 0,
                        }}
                      />
                    </div>
                    <img
                      alt={`${selectedLanguage} flag`}
                      src={`${baseFlagUrl + mapFlagsIso[iso]}.svg`}
                      decoding="async"
                      data-nimg="intrinsic"
                      className="w-full"
                      style={{
                        position: "absolute",
                        inset: 0,
                        boxSizing: "border-box",
                        padding: 0,
                        border: "none",
                        margin: "auto",
                        display: "block",
                        width: 0,
                        height: 0,
                        minWidth: "100%",
                        maxWidth: "100%",
                        minHeight: "100%",
                        maxHeight: "100%",
                      }}
                    />
                    <noscript />
                  </div>
                </span>
                {title}
                <span className={`absolute inset-y-0 left-0 flex items-center pl-2 text-yellow-600 ${selectedLanguage === iso ? '' : 'hidden'}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default memo(HeaderLanguagePicker);
