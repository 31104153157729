import React, { memo } from 'react';
import PropType from 'prop-types';
import { FormattedMessage } from 'react-intl';
import original from '../../assets/img/modelCreation/original.png';
import extracted from '../../assets/img/modelCreation/extracted.png';
import camera from '../../assets/img/modelCreation/camera.png';
import arrow from '../../assets/img/modelCreation/arrow.svg';
import FreeTrialButton from '../common/freeTrialButton';
import BeforeAfterSliderComponent from '../common/beforeAfterSliderComponent';

const VerticalElement = ({ imgSRC, text, locale = 'en' }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <div className="row flex-column">
    <div className="d-flex justify-content-center align-items-center col-12 mb-2">
      <div>
        <img src={imgSRC} alt="" />
      </div>
    </div>
    <div className="d-flex flex-column justify-content-center align-items-center col-12">
      <p className={`text ${locale === 'es' ? 'order-3' : 'order-1'}`}>{text[0]}</p>
      <p className="text order-2">{text[1]}</p>
    </div>
  </div>
);
VerticalElement.propTypes = {
  imgSRC: PropType.string,
  text: PropType.any,
  locale: PropType.string,
};

const VerticalArrow = memo(() => (
  <div className="row m-3">
    <div className="d-flex justify-content-center align-items-center col-12">
      <div style={{ transform: 'rotate(90deg)' }}>
        <img src={arrow} alt="" />
      </div>
    </div>
  </div>
));

const before = '/img/process/before.png';
const after = '/img/process/after.png';

const AutomaticModelCreation = ({ locale }) => {
  return (
    <>
      <div id="animationtop" />
      <div className="d-none d-sm-block container">
        <div className="container flex flex-row items-center justify-center pt-8 pb-8 beforeAfter" style={{ width: "430px", height: "480px" }}>
          <BeforeAfterSliderComponent before={before} after={after} />
        </div>
        <div className="row mt-3">
          <div className="d-flex flex-column justify-content-center align-items-center col">
            <p className="text-3xl font-bold" style={{ color: '#CE0CC8' }}>
              <FormattedMessage id="UPLOAD" />
            </p>
            <p className="text-3xl font-bold" style={{ color: '#CE0CC8' }}>
              <FormattedMessage id="PICTURE" />
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center col">
            <div>
              <img src={arrow} alt="" />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center col">
            <p className={`text-3xl font-bold ${locale === 'es' ? 'order-3' : 'order-1'}`} style={{ color: '#CE0CC8' }}>
              <FormattedMessage id="AUTOMATIC" />
            </p>
            <p className="text-3xl font-bold order-2" style={{ color: '#CE0CC8' }}>
              <FormattedMessage id="RENDERING" />
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center col">
            <div>
              <img src={arrow} alt="" />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center col">
            <p className="text-3xl font-bold" style={{ color: '#CE0CC8' }}>
              <FormattedMessage id="VIEW_IN" />
            </p>
            <p className="text-3xl font-bold" style={{ color: '#CE0CC8' }}>
              <FormattedMessage id="AR_APP" />
            </p>
          </div>
        </div>
      </div>
      <div className="d-sm-none container flex flex-row items-center justify-center beforeAfter2" style={{ width: "400px", height: "400px" }}>
        {/* <VerticalElement
          locale={locale}
          text={[<FormattedMessage id="UPLOAD" />, <FormattedMessage id="PICTURE" />]}
        />
        <VerticalArrow />
        <VerticalElement
          locale={locale}
          text={[<FormattedMessage id="AUTOMATIC" />, <FormattedMessage id="RENDERING" />]}
        />
        <VerticalArrow />
        <VerticalElement
          locale={locale}
          text={[<FormattedMessage id="VIEW_IN" />, <FormattedMessage id="AR_APP" />]}
        /> */}
        <BeforeAfterSliderComponent before={before} after={after} />
      </div>
      <section className="flex flex-col items-center py-4">
        <FreeTrialButton
          classes="free-trial-account text-white mt-8"
          text={<FormattedMessage id="SIGNUP" />}
        />
      </section>
    </>
  );
};
AutomaticModelCreation.propTypes = {
  locale: PropType.string,
};

export default memo(AutomaticModelCreation);
