/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PricingCountUp from './pricingCountUp';
import PlanFeatures from './planFeatures';

function BasicPricingCard({
  name,
  priceMonth,
  priceYear,
  description,
  billingPeriod,
  firstRender,
  handleChangeSelectedPlan,
}) {
  function PriceAmount() {
    return (
      <PricingCountUp
        price={billingPeriod === 'monthly' ? priceMonth : priceYear}
        startFrom={billingPeriod === 'monthly' ? priceYear : priceMonth}
      />
    );
  }
  function PriceAmountAnimation() {
    switch (billingPeriod) {
      case 'monthly':
        return priceMonth;
      default:
        return priceYear;
    }
  }
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <>
      <div
        role="listitem"
        className="bg-white shadow rounded-xl mt-3 flex relative z-30 w-full md:w-80"
        style={{ minHeight: '350px' }}
      >
        <div className="w-full p-4 flex flex-col justify-between">
          <p className="m-0 text-center text-3xl font-bold" style={{ color: '#BD10E0' }}>
            <FormattedMessage id={name} />
          </p>
          <div>
            <p className="text-base p-3 font-bold">
              <FormattedMessage id={description} />
            </p>
            <p className="leading-10 text-center text-4xl md:mt-0 mt-4 font-bold leading-6 mb-3">
              € {firstRender ? <PriceAmountAnimation /> : <PriceAmount />}
            </p>
            <p className="text-center font-semibold text-lg my-3">
              <FormattedMessage id="FREE_TRIAL_PRICING" />
            </p>
            <button
              type="button"
              className="w-full text-blue-500 border-2 border-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 font-semibold py-3 rounded-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              onClick={handleChangeSelectedPlan}
            >
              <FormattedMessage id="SUBSCRIBE" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function RecommendedPricingCard({
  name,
  priceMonth,
  priceYear,
  description,
  billingPeriod,
  firstRender,
  handleChangeSelectedPlan,
}) {
  function PriceAmount() {
    return (
      <PricingCountUp
        price={billingPeriod === 'monthly' ? priceMonth : priceYear}
        startFrom={billingPeriod === 'monthly' ? priceYear : priceMonth}
      />
    );
  }
  function PriceAmountAnimation() {
    switch (billingPeriod) {
      case 'monthly':
        return priceMonth;
      default:
        return priceYear;
    }
  }
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      role="listitem"
      className="bg-green-300 shadow rounded-xl mt-3 flex relative z-30 w-full md:w-80"
      style={{ minHeight: '400px' }}
    >
      <div className="w-full p-4 flex flex-col justify-between">
        <p className="m-0 text-center text-3xl font-bold" style={{ color: '#000' }}>
          <FormattedMessage id={name} />
        </p>
        <div>
          <p className="text-base p-3 font-bold">
            <FormattedMessage id={description} />
          </p>
          <p className="leading-10 text-center md:mt-0 mt-4 font-bold leading-6 text-5xl mb-3">
            €{firstRender ? <PriceAmountAnimation /> : <PriceAmount />}
          </p>
          <p className="text-center font-semibold text-lg my-3">
            <FormattedMessage id="FREE_TRIAL_PRICING" />
          </p>
          <button
            type="button"
            className="w-full text-blue-500 border-2 border-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 font-semibold py-3 rounded-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            onClick={handleChangeSelectedPlan}
          >
            <FormattedMessage id="SUBSCRIBE" />
          </button>
        </div>
      </div>
    </div>
  );
}

function FlexiblePricingCard({ name, description }) {
  const history = useHistory();
  return (
    <div
      role="listitem"
      className="bg-gray-200 shadow rounded-xl mt-3 flex relative z-30 w-full md:w-80"
      style={{ minHeight: '350px' }}
    >
      <div className="w-full p-4 flex flex-col justify-between">
        <p className="m-0 text-center text-3xl font-bold" style={{ color: '#BD10E0' }}>
          <FormattedMessage id={name} />
        </p>
        <div>
          <p className="text-base p-3 font-bold">
            <FormattedMessage id={description} />
          </p>
          <p className="leading-10 text-center text-4xl md:mt-0 mt-4 font-bold leading-6 mb-3">
            <FormattedMessage id="CUSTOM_PRICING" />
          </p>
          <p className="text-center font-semibold text-lg my-3">
            <br />
          </p>
          <button
            type="button"
            className="w-full text-blue-500 border-2 border-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 font-semibold py-3 rounded-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            onClick={() => history.push('/contact')}
          >
            <FormattedMessage id="GET_QUOTE" />
          </button>
        </div>
      </div>
    </div>
  );
}

function PricingCard(props) {
  const { features } = props;

  let component = <BasicPricingCard {...props} />;
  if (props.featured) component = <RecommendedPricingCard {...props} />;
  if (!props.featured && !props.freeTrial) component = <FlexiblePricingCard {...props} />;

  return (
    <div className="w-full md:w-auto">
      {component}
      <div className="d-block d-md-none">
        <PlanFeatures features={features} />
      </div>
    </div>
  );
}

PricingCard.propTypes = {
  name: PropType.string,
  priceMonth: PropType.number,
  priceYear: PropType.number,
  description: PropType.string,
  featured: PropType.bool,
  billingPeriod: PropType.string,
  firstRender: PropType.bool,
  handleChangeSelectedPlan: PropType.func,
  freeTrial: PropType.bool.isRequired,
  features: PropType.array,
};

export default memo(PricingCard);
