import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux/Store';

// Importing the Bootstrap CSS
import 'bootstrap-css-only/css/bootstrap.min.css';
// Importing the MDB css
// import 'mdbreact/dist/css/mdb.css';
// Importing custom css
import './assets/css/hamburgers.css';
import './assets/sass/screen.scss';
import 'animate.css/animate.min.css';

ReactDOM.render(<Router><Provider store={store}><App /></Provider></Router>, document.getElementById('root'));

serviceWorker.register();
