import React, { useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import { useHistory } from "react-router-dom";
import arLogo from "../../assets/img/ar-logo.svg";
import arrow from '../../assets/img/modelCreation/arrow.svg';
import { goToAR } from "../../utils/augmentedReality";
import FreeTrialButton from "../common/freeTrialButton";
import product from "./customArBannerChairProduct.json";

const chair = "https://yuge-images-product.goyuge.com/extraction/%2B34935131954_1505902340326.png?Expires=33135054239&Signature=jd7Sho9ZtzE9pdiUbvWE22qbspWrin9FkIsSFmWYAjQrrV0tFPAw~ndCX6RF0jWJ3aHa6kQ9OW~9lqMRnUyRhonZrXuFTfW3Q3~isIyYwer8meSbYZWeG6IucYABz-ZJNbr4Z0KhMCxqsZkxrarBpLbdMnJvxYwGGtehf7nYpZ4suN0vAuPDuOoQizQuk~yxJNsOtYV4oTVz2d6XwRGwJ2NXRjCpOwA2ld1mLLtKb0mqJO0onuVgBaVYCI9e2ZC3wO~4freXgmV09E-QRarFpoeO75OqsvV6gif89thdxF4e3NTodVJc1misekeU32Mlm3Kn5i9zF96NbWqqMtaeTg__&Key-Pair-Id=APKAJFRT5R5NZTB74DJA";

export default function CustomArBanner() {
  const [isMobile, setIsMobile] = useState({
    isAndroid: false,
    isIOS: false,
  });
  const history = useHistory();

  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setIsMobile({
        isIOS: false,
        isAndroid: true,
      });
    }
    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      setIsMobile({
        isAndroid: false,
        isIOS: true,
      });
    }
  }, []);

  const handleOnClickProduct = (e) => {
    e.preventDefault();
    goToAR(product.product, () => history.push("/product/59bfb2265b45f90010f27fa9"));
  };

  return (
    <section className="flex flex-col items-center py-10">
      <h1 className="font-semibold text-center text-yellow-400 text-6xl"><FormattedMessage id="MAIN_TITLE" /></h1>
      <p className="mt-3 text-center text-gray-400 text-3xl"><FormattedMessage id="MAIN_SUBTITLE" /></p>
      <div className="cursor-pointer mt-6 relative rounded-lg border-gray-400 border-2" onClick={handleOnClickProduct} style={{ width: '360px', height: '360px' }}>
        <img className="z-20 w-16 absolute right-2 top-2 shadow rounded-full" src={arLogo} alt="AR Supported" />
        <img className="w-full" src={chair} alt="AR Char" />
      </div>
      <a href="/product/59bfb2265b45f90010f27fa9" onClick={handleOnClickProduct} className="mt-4 text flex flex-row">
        <FormattedMessage id="MAIN_CTA" />
        <div>
          <img className="ml-3" src={arrow} alt="" />
        </div>
      </a>

      <p className="text-xl mt-16 md:w-3/4 text-center text-gray-800 font-semibold md:text-4xl"><FormattedMessage id="MAIN_DESCRIPTION" values={{ br: <br /> }} /></p>

      <FreeTrialButton
        classes="free-trial-account text-white mt-8 w-40"
        text={<FormattedMessage id="FREE_TRIAL_ACCOUNT" />}
      />
    </section>
  );
}
