import React, { memo } from 'react';
import PropType from 'prop-types';

const TextBlock = ({ title, title2 = null, subtitle, margin = "mt-3" }) => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="container">
      <div className="row">
        <div className="w-full">
          <h1 className="font-semibold">{title}</h1>
          {title2 && <h1 className="p-0 font-semibold" style={{ marginTop: 0 }}>{title2}</h1> }
          <p className={`${margin} text-center text-gray-400 text-3xl`}>{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

TextBlock.propTypes = {
  title: PropType.any,
  title2: PropType.any,
  subtitle: PropType.any,
  margin: PropType.any,
};

export default memo(TextBlock);
