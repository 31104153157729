import React, { memo } from 'react';
import PropType from 'prop-types';
import TextBlock from './title';

const Block = ({ title, title2, subtitle, margin, children }) => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <TextBlock title={title} title2={title2} subtitle={subtitle} margin={margin} />
      {children}
    </>
  );
};
Block.propTypes = {
  title: PropType.any,
  title2: PropType.any,
  subtitle: PropType.any,
  children: PropType.any,
  margin: PropType.any,
};
export default memo(Block);
