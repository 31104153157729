import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Block from './technology/block';
import ImageColumns from './technology/imageColumns';
import TextBlock from './technology/title';
import PrioritizedMerchantGrid from './technology/prioritizedMerchantGrid';
import FreeTrialButton from './common/freeTrialButton';
import AutomaticModelCreation from './technology/automacticModelCreation';
import CustomArBanner from './technology/customArBanner';
import Referrals from './technology/referrals';
import Hosting from './technology/hosting';
import Loading from './common/loading';

// eslint-disable-next-line react/prop-types
const Technology = ({ locale, onMount }) => {
  useEffect(() => {
    onMount('Technology');
  }, []);
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="page-index">
      {/* Site Content */}
      <CustomArBanner />
      <Block
        title={<FormattedMessage id="AUTOMATIC_MODEL_CREATION" />}
        subtitle={<FormattedMessage id="AUTOMATIC_MODEL_CREATION_SUBTITLE" />}
      >
        <p className="text-xl mb-8 md:mb-0 mt-3 text-center text-gray-800 md:text-4xl font-semibold">
          <FormattedMessage id="AUTOMATIC_MODEL_CREATION_DESCRIPTION" values={{ br: <br /> }} />
        </p>
        <AutomaticModelCreation locale={locale} />
      </Block>

      <Block>
        <div className="row row justify-content-center">
          <div className="col-8">
            <h1 className="font-semibold text-center text-yellow-400 text-6xl">
              <FormattedMessage id="INDEX_1_HEADER_1" />
            </h1>
          </div>
        </div>

        <ImageColumns productPage="/product/chair2" />
      </Block>

      <Block
        title={<FormattedMessage id="INDEX_0_HEADER_1" />}
        title2={<FormattedMessage id="INDEX_0_HEADER_11" />}
        subtitle={<FormattedMessage id="INDEX_0_HEADER_2" />}
        margin="m-16"
      >
        <p className="text-xl mb-24 text-center text-gray-800 font-semibold md:text-4xl">
          <FormattedMessage id="TECH_INCREASE_SALES" values={{ br: <br /> }} />
        </p>
      </Block>

      <Referrals />

      <Hosting />

      <TextBlock title={<FormattedMessage id="INDEX_3_HEADER_1" />} />
      <PrioritizedMerchantGrid />
      <div className="flex flex-col items-center mb-10">
        <FreeTrialButton
          classes="free-trial-account text-white mt-6"
          text={<FormattedMessage id="TRY_HERE_BUTTON" />}
        />
      </div>
    </div>
  );
};
Technology.propTypes = {
  locale: PropTypes.string,
};
export default memo(Technology);
