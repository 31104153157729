/* eslint-disable react/jsx-props-no-spreading */
import PropType from 'prop-types';
import { useState } from 'react';
import PlanFeatures from './planFeatures';
import PricingCard from './pricingCard';

const plans = [
  {
    name: 'P_1_NAME',
    priceMonth: 19,
    priceYear: 228,
    description: 'P_1_DESC',
    featured: false,
    freeTrial: true,
    features: ['PF_1_1', 'PF_1_2'],
  },
  {
    name: 'P_2_NAME',
    priceMonth: 155,
    priceYear: 1860,
    description: 'P_2_DESC',
    featured: true,
    freeTrial: true,
    features: ['PF_2_1', 'PF_2_2', 'PF_2_3', 'PF_2_4', 'PF_2_5', 'PF_2_6'],
  },
  {
    name: 'P_3_NAME',
    priceMonth: '',
    priceYear: '',
    description: 'P_3_DESC',
    featured: false,
    freeTrial: false,
    features: ['PF_3_1', 'PF_3_2', 'PF_3_3'],
  },
];

/* eslint-disable react/react-in-jsx-scope */
function PricingGrid({ billingPeriod, firstRender, handleSelectPlan }) {
  const [selectedPlan, setSelectedPlan] = useState(-1);
  function handleChangeSelectedPlan(planId) {
    handleSelectPlan();
    if (selectedPlan === planId) {
      setSelectedPlan(-1);
      return;
    }
    setSelectedPlan(planId);
  }
  return (
    <>
      <div
        className="relative w-full lg:mt-0 flex items-end justify-center flex-wrap gap-x-0 md:gap-x-20"
        role="list"
      >
        {plans.map((plan, index) => (
          <PricingCard
            firstRender={firstRender}
            billingPeriod={billingPeriod}
            {...plan}
            handleChangeSelectedPlan={() => handleChangeSelectedPlan(index)}
          />
        ))}
      </div>
      <div
        className="relative w-full lg:mt-0 flex items-start justify-center flex-wrap gap-x-0 md:gap-x-20 d-none d-md-flex"
        role="list"
      >
        {plans.map((plan) => (
          <PlanFeatures features={plan.features} />
        ))}
      </div>
    </>
  );
}

PricingGrid.propTypes = {
  billingPeriod: PropType.string,
  firstRender: PropType.bool,
  handleSelectPlan: PropType.func,
};

export default PricingGrid;
