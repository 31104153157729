import React, { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropType from 'prop-types';
import '../../assets/sass/_btn-free-trial.scss';
import FreeTrialModal from './freeTrialModal';

const FreeTrialButton = ({ classes, text }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a className={classes} onClick={handleShow} style={{ whiteSpace: 'nowrap' }}>
        {text || <FormattedMessage id="FREE_TRIAL" />}
      </a>
      <FreeTrialModal show={show} onClose={handleClose} />
    </>
  );
};
FreeTrialButton.propTypes = {
  classes: PropType.string,
  text: PropType.any,
};
export default memo(FreeTrialButton);
