import { put, takeLatest, all, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  FETCH_CATEGORIES,
  CREATE_TRIAL_ACCOUNT,
  CREATE_ACCOUNT,
  LOGIN_BY_EMAIL,
  CONFIRM_EMAIL,
  GET_SHOPS,
  GET_BASKET,
} from './ActionTypes';
import http from '../services/httpService';
import {
  LIVE,
  searchPlusPagination,
  registerMerchantByEmail,
  registerUserByEmail,
  loginUserByEmail,
  confirmRegisterUserByEmail,
} from '../api/yugeAPI2';
import {
  hideLoading,
  showLoading,
  receivedCategories,
  receivedShops,
  setEmailSent,
  setErrorTrialMessage,
  emailConfirmedAction,
  showLoginModalAction,
  setUserAction,
} from './Actions';

const createAccountMerchant = (data) => {
  const { email, password, storeName } = data;
  return axios.post(registerMerchantByEmail, {
    live: LIVE,
    email,
    password,
    merchantName: storeName,
  });
};

const createAccountByEmail = (data) => {
  const { email, password, name } = data;
  return axios.post(registerUserByEmail, {
    live: LIVE,
    email,
    password,
    name,
  });
};

const loginByEmail = (data) => {
  const { email, password } = data;
  const params = new URLSearchParams();
  params.append('live', LIVE);
  params.append('username', email);
  params.append('password', password);

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return axios.post(loginUserByEmail, params, config);
};

const confirmEmail = (data) => {
  const { email, code } = data;
  return axios.post(confirmRegisterUserByEmail, {
    live: LIVE,
    email,
    securityCode: code,
  });
};

function getShops({
  config,
  pageNumber,
  itemsPerPage,
  byCategory,
  withPriority,
  queryCompany,
  isWebShop = false,
}) {
  const data = {
    live: LIVE,
    filter: {
      publish: true,
      publishOnWeb: true,
      withImages: true,
      sortPriorityCompanyName: true,
    },
    paginationByPageNumbers: {
      page: pageNumber,
      elements: itemsPerPage,
    },
  };

  if (byCategory !== '' && !isWebShop) {
    data.filter.category = byCategory;
  }

  if (isWebShop) {
    data.filter.isWebShop = true;
  }

  if (withPriority) {
    data.filter.priority = true;
  }

  if (queryCompany !== '') {
    data.filter.queryCompany = queryCompany;
  }

  return axios.post(searchPlusPagination, data, config);
}

function getCategories(config) {
  const values = {
    live: LIVE,
    filter: {
      region: 'ES',
      published: true,
      sortByOrder: true,
    },
  };
  return http.post('/categories/search', values, config);
}

function* createTrialAccountSaga(params) {
  try {
    yield put(showLoading());
    yield call(createAccountMerchant, params.payload);
    yield put(setEmailSent(true));
  } catch (error) {
    if (error.response.status === 412) {
      yield put(setErrorTrialMessage('The email is already register in Yuge.'));
      return;
    }
    if (error.response.status === 400) {
      yield put(setErrorTrialMessage('The store name is already register in Yuge.'));
      return;
    }
    if (error.response.status === 406) {
      yield put(setErrorTrialMessage('The password is not strong enough to register in Yuge.'));
      return;
    }
    yield put(
      setErrorTrialMessage('Service is unavailable at the moment. Please, try again later!'),
    );
  } finally {
    yield put(hideLoading());
  }
}

function* createAccountSaga(params) {
  try {
    yield put(showLoading());
    yield call(createAccountByEmail, params.payload);
    yield put(setEmailSent(true));
  } catch (error) {
    if (error.response.status === 412) {
      yield put(setErrorTrialMessage('The email is already register in Yuge.'));
      return;
    }
    if (error.response.status === 406) {
      yield put(setErrorTrialMessage('The password is not strong enough to register in Yuge.'));
      return;
    }
    yield put(
      setErrorTrialMessage('Service is unavailable at the moment. Please, try again later!'),
    );
  } finally {
    yield put(hideLoading());
  }
}

function* loginSaga(params) {
  try {
    yield put(showLoading());
    const user = yield call(loginByEmail, params.payload);
    yield put(showLoginModalAction(false));
    if (user && user.data) {
      localStorage.setItem('access_token', user.data.access_token);
      localStorage.setItem('username', params.payload.email);
      yield put(setUserAction({ ...user.data, username: params.payload.email }));
    }
  } catch (error) {
    if (error.response.status === 400) {
      yield put(setErrorTrialMessage('Invalid credentials.'));
      return;
    }
    yield put(
      setErrorTrialMessage('Service is unavailable at the moment. Please, try again later!'),
    );
  } finally {
    yield put(hideLoading());
  }
}

function* confirmByEmailSaga(params) {
  try {
    yield put(showLoading());
    yield call(confirmEmail, params.payload);
    yield put(emailConfirmedAction(true));
    yield put(showLoginModalAction(true));
  } catch (error) {
    yield put(showLoginModalAction(true));
    if (error.response.status === 412) {
      yield put(setErrorTrialMessage('Expired confirmation link used.'));
      return;
    }
    yield put(
      setErrorTrialMessage('Service is unavailable at the moment. Please, try again later!'),
    );
  } finally {
    yield put(hideLoading());
  }
}

function* fetchShopsSaga(params) {
  yield put(showLoading());
  const result = yield call(getShops, params.payload);
  if (result.status === 200) {
    yield put(
      receivedShops(
        result.data,
        params.payload.pageNumber > 1 || params.payload.queryCompany === '',
      ),
    );
  }
  yield put(hideLoading());
}

function* fetchCategoriesSaga(config) {
  yield put(showLoading());
  const result = yield call(getCategories, config.payload);
  if (result.status === 200) {
    yield put(receivedCategories(result.data));
  }
  yield put(hideLoading());
}

function getBasketCall(token) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const values = {
    live: LIVE,
  };
  return http.post('/sales/get-basket', values, config);
}

function* getUserBasketSaga(action) {
  console.log('osielu', action);
  const result = yield call(getBasketCall, action.payload.access_token);
  console.log('osiel', result);
  yield put(showLoading());
}

function* actionWatcher() {
  yield takeLatest(GET_SHOPS, fetchShopsSaga);
  yield takeLatest(FETCH_CATEGORIES, fetchCategoriesSaga);
  yield takeLatest(CREATE_TRIAL_ACCOUNT, createTrialAccountSaga);
  yield takeLatest(CREATE_ACCOUNT, createAccountSaga);
  yield takeLatest(LOGIN_BY_EMAIL, loginSaga);
  yield takeLatest(CONFIRM_EMAIL, confirmByEmailSaga);
  yield takeLatest(GET_BASKET, getUserBasketSaga);
}
export default function* rootSaga() {
  yield all([actionWatcher()]);
}
