/* eslint-disable quote-props */
import React, { memo, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, useParams } from 'react-router-dom';
import getProduct from '../services/productByIdService';
import ScrollToTop from './common/scrollToTop';
import Chair3 from '../assets/img/cam/barcelona2.glb';
import Chair3iOS from '../assets/img/cam/barcelona2.usdz';
import ProductPreview3D from './product/productPreview3D';
import { goToAR } from '../utils/augmentedReality';

const chair = {
  productId: 'chair2',
  usdzFiles: [
    {
      URL: Chair3,
    },
  ],
  glbFiles: [
    {
      URL: Chair3iOS,
    },
  ],
};

// eslint-disable-next-line react/prop-types
const ProductPage = () => {
  const [product, setProduct] = useState(null);
  const w = '400px';
  const h = '400px';
  const { productId } = useParams();
  const [isMobile, setIsMobile] = useState({
    isAndroid: false,
    isIOS: false,
  });

  const redirectToArView = () => {
    goToAR(product, () => {});
  };

  function getQrUrl() {
    return `https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl=${window.location}&choe=UTF-8`;
  }

  function populateProduct() {
    getProduct(productId)
      .then(({ data: productR }) => {
        if (productR.extractions.length > 0) {
          productR.featuredImage = productR.extractions[0].URL;
        } else {
          productR.featuredImage = productR.images[0].thumbBigURL;
        }
        setProduct(productR);
      })
      .catch(() => {
        setProduct(false);
      });
  }

  useEffect(() => {
    if (product) redirectToArView();
  }, [product]);

  useEffect(() => {
    if (productId && productId !== chair.productId) populateProduct();
    else setProduct(chair);
  }, [productId]);

  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setIsMobile({
        isIOS: false,
        isAndroid: true,
      });
    }
    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      setIsMobile({
        isAndroid: false,
        isIOS: true,
      });
    }
  }, []);

  if (product === false) {
    return <Redirect to="/not-found" />;
  }

  const RenderProductPreview3D = () => (
    <ProductPreview3D src={product.usdzFiles[0].URL} arImg={product.glbFiles[0].URL} w={w} h={h} />
  );

  return (
    <div
      className="w-full py-10 flex justify-center items-center flex-col-reverse md:flex-row"
      style={{ minHeight: '80vh' }}
    >
      <ScrollToTop />
      <div className="w-1/2 flex flex-col items-center justify-center" style={{ width: w, height: h }}>
        <img className="w-3/4" src={getQrUrl(productId)} alt="Product QR" />
        <p className="mt-4 text-xl font-semibold">
          <FormattedMessage id="SCAN_QR" values={{ br: <br /> }} />
        </p>
      </div>
      <div className="w-36"> </div>
      <div
        className="border rounded-xl flex items-center justify-center"
        style={{ width: w, height: h, background: 'url("/transparentBackground.png")' }}
      >
        {product === chair && RenderProductPreview3D()}
        {product && product !== chair && <img className="w-full h-auto" src={product.featuredImage} alt="Product Preview" />}
      </div>
    </div>
  );
};
export default memo(ProductPage);
