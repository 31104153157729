import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

const MerchantPreview = (props) => {
  // eslint-disable-next-line react/prop-types,react/destructuring-assignment
  const { slug, images, companyName, city } = props.merchant;
  const { push } = useHistory();
  // eslint-disable-next-line react/prop-types
  let imageURL = images && images.length ? images[0].mobileURL : 'no-image';
  if (!imageURL.includes('https')) {
    imageURL = imageURL.replace('http', 'https');
  }

  const onItemClick = event => {
    event.stopPropagation();
    event.preventDefault();
    push(`/shops/${slug}`);
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={`/shops/${slug}`} onClick={onItemClick} className="cursor-pointer max-h-[488px] overflow-hidden relative w-full relative rounded-lg" style={{ aspectRatio: '16/9' }}>
      <img className="shadow-lg rounded-lg" src={imageURL} alt="Ana Furniture" loading="lazy" />
      <div className="absolute h-full w-full top-0 flex flex-col justify-end p-4 lg:justify-center lg:items-center absolute font-semibold text-white z-50" style={{ textShadow: "black 0px 0px 15px" }}>
        <h2 className="text-white text-2xl font-semibold">{companyName}</h2>
        <h3 className="text-white text-sm font-semibold">{city}</h3>
      </div>
    </a>
  );
};
export default memo(MerchantPreview);
