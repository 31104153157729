function init() {}

function log(error) {
  // eslint-disable-next-line no-console
  console.error(error);
}

export default {
  init,
  log,
};
