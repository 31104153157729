import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Accordion, Button } from "react-bootstrap";
import lottie from "lottie-web";
import NavBarHeader from './navBarHeader';
import NavBarDrawer from './navBarDrawer';
import { selectShowCart, selectUser } from '../../redux/Selectors';
import { getUserBasket, setUserAction, showLoginModalAction } from '../../redux/Actions';
import SubTotal from "../shopping cart/SubTotal";
import TaxesFees from "../shopping cart/TaxesFees";
import EstimatedTotal from "../shopping cart/EstimatedTotal";
import ItemDetails from "../shopping cart/ItemDetails";
import StepsCart, { cartSteps, cartStepsStates } from "./stepsViewCart";
import checkedRadio from '../../assets/img/shoppingCart/checked_radio.png';
import doneAnimation from '../../assets/img/shoppingCart/done_animation.json';
import FreeTrialModal from './freeTrialModal';
import { MERCHANT_STAGE_URL } from '../../api/yugeAPI2';
import AndroidSmartBanner from './androidSmartBanner';

const styles = {
  buttons: {
    backgroundColor: '#ffb000',
    borderColor: '#ffb000',
    marginLeft: 10,
  },
  inputForm: {
    marginBottom: 15,
  },
  formHeaderText: {
    color: '#666666',
  },
};

const NavBar = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const showCart = useSelector(selectShowCart);
  const [classList, setClassList] = useState([]);
  const [drawerState, setDrawerState] = useState('');
  const [logoKey, setLogoKey] = useState(0);
  // const showLoginModal = useSelector(selectShowLoginModal);
  const [currentCartStep, setCurrentCartStep] = useState(cartSteps.cartView);
  const [currentCartStepState, setCurrentCartStepState] = useState(cartStepsStates.editing);
  const menuItems = [
    {
      href: '/',
      text: 'TECHNOLOGY',
      external: false,
    },
    {
      href: '/apps',
      text: 'APPS',
      external: false,
    },
    {
      href: '/pricing',
      text: 'PRICING',
      external: false,
    },
  ];
  const { selectedLanguage, onLanguageChange } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('username');
    dispatch(setUserAction(null));
  };

  if (!user || !user.access_token || user.access_token === 'undefined' || user.access_token === '') {
    menuItems.push({
      href: MERCHANT_STAGE_URL,
      action: 'login',
      text: 'LOGIN',
      external: true,
    });
    dispatch(setUserAction(null));
  } else {
    menuItems.push({
      href: '/logout',
      action: 'logout',
      text: 'LOGOUT',
      external: false,
    });
  }

  const toggleLogoKey = () => {
    const lKey = logoKey === 0 ? 1 : 0;
    setLogoKey(lKey);
  };

  const handleActionsInItem = (item) => {
    if (item.action === 'login') {
      dispatch(showLoginModalAction(true));
    }
    if (item.action === 'logout') {
      logout();
    }
    if (item.action === 'signup') {
      handleShow();
    }
  };

  const handleOnClickEvent = (item) => {
    toggleLogoKey();

    if (item && item.action) {
      handleActionsInItem(item);
    }
  };

  const handleOnClickItem = (item) => {
    toggleLogoKey();
    setDrawerState('d-none');
    let cl = classList;
    cl = cl.toggle('is-active');
    setClassList(cl);
    // eslint-disable-next-line react/prop-types
    props.onDrawerStateChange('close');

    if (item && item.action) {
      handleActionsInItem(item);
    }
  };

  const handleOnClickHamburger = (cl) => {
    setDrawerState('');
    cl.toggle('is-active');
    const ds = cl.contains('is-active') ? 'openDrawer' : '';
    setClassList(cl);
    setDrawerState(ds);
    // eslint-disable-next-line react/prop-types
    props.onDrawerStateChange(ds);
  };

  const handleOnMouseOverHamburger = () => {
    if (drawerState === 'd-none') {
      setDrawerState('');
    }
  };

  const onNextBackCartPressed = (isNext) => {
    if (currentCartStep === cartSteps.cartView) {
      if (isNext) {
        setCurrentCartStep(cartSteps.shippingView);
      }
      return;
    }
    if (currentCartStep === cartSteps.shippingView) {
      if (isNext) {
        setCurrentCartStep(cartSteps.paymentView);
      } else {
        setCurrentCartStep(cartSteps.cartView);
      }
    }
    if (currentCartStep === cartSteps.paymentView) {
      if (isNext) {
        setCurrentCartStep(cartSteps.reviewView);
      } else {
        setCurrentCartStep(cartSteps.shippingView);
      }
    }
    if (currentCartStep === cartSteps.reviewView) {
      if (isNext) {
        setCurrentCartStep(cartSteps.finishView);
      } else {
        setCurrentCartStep(cartSteps.paymentView);
      }
    }

    if (currentCartStep === cartSteps.finishView) {
      if (!isNext) {
        setCurrentCartStep(cartSteps.reviewView);
      }
    }
  };

  useEffect(() => {
    if (currentCartStep === cartSteps.finishView) {
      lottie.loadAnimation({
        container: document.querySelector("#buy-result-anim"),
        animationData: doneAnimation,
        renderer: "svg", // "canvas", "html"
        loop: false, // boolean
        autoplay: true, // boolean
      });
    }
  }, [currentCartStep]);

  useEffect(() => {
    if (showCart && user) {
      dispatch(getUserBasket(user));
    }
  }, [showCart, user]);

  // eslint-disable-next-line react/prop-types
  const { currentPath, logokey } = props;
  const headerClasses = 'header-wrap';
  const drawerClasses = 'header-wrap';
  const logoClassName = 'logo';

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <AndroidSmartBanner />
      <NavBarHeader
        menuItems={menuItems}
        logoClassName={logoClassName}
        classes={headerClasses}
        logoKey={logoKey + logokey}
        onClickEvent={handleOnClickEvent}
        onClickHamburger={handleOnClickHamburger}
        onMouseOverHamburger={handleOnMouseOverHamburger}
        selectedLanguage={selectedLanguage}
        onLanguageChange={onLanguageChange}
      />
      <NavBarDrawer
        menuItems={menuItems}
        classes={drawerClasses}
        state={drawerState}
        onClickItem={handleOnClickItem}
      />
      {/* <LoginModal show={showLoginModal} onClose={() => dispatch(showLoginModalAction(false))} /> */}
      <Accordion activeKey={showCart ? "cart" : ""}>
        <Accordion.Collapse eventKey="cart">
          <div className="purchase-card">
            <Container>
              <StepsCart step={currentCartStep} state={currentCartStepState} />
              {currentCartStep === cartSteps.cartView
              && (
                <>
                  <SubTotal />
                  <br />
                  <TaxesFees />
                  <hr />
                  <EstimatedTotal />
                  <br />
                  <ItemDetails />
                </>
              )}
              <div className="flex justify-center items-center" style={{ marginTop: 20 }}>
                {currentCartStep === cartSteps.shippingView
                && (
                  <div style={{ width: 570 }}>
                    <span style={styles.formHeaderText}>Name:</span>
                    <input
                      required
                      type="text"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                      placeholder="Full name"
                      value=""
                      onChange={() => {}}
                      style={styles.inputForm}
                    />
                    <span style={styles.formHeaderText}>Country:</span>
                    <input
                      required
                      type="text"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                      placeholder="Country"
                      value=""
                      onChange={() => {}}
                      style={styles.inputForm}
                    />
                    <span style={styles.formHeaderText}>City:</span>
                    <input
                      required
                      type="text"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                      placeholder="City"
                      value=""
                      onChange={() => {}}
                      style={styles.inputForm}
                    />
                    <span style={styles.formHeaderText}>Street address:</span>
                    <input
                      required
                      type="text"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                      placeholder="Street address"
                      value=""
                      onChange={() => {}}
                      style={styles.inputForm}
                    />
                    <span style={styles.formHeaderText}>Postal code:</span>
                    <input
                      required
                      type="text"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                      placeholder="Postal code"
                      value=""
                      onChange={() => {}}
                      style={styles.inputForm}
                    />
                    <span style={styles.formHeaderText}>Apt / Suite / Other (optional):</span>
                    <input
                      required
                      type="text"
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                      placeholder="Other"
                      value=""
                      onChange={() => {}}
                      style={styles.inputForm}
                    />
                  </div>
                )}
                {currentCartStep === cartSteps.paymentView
                && (
                  <div style={{ width: 570 }}>
                    <span style={styles.formHeaderText}>Payment method:</span>
                    <div className="flex justify-start items-center" style={{ marginTop: 10 }}>
                      <img src={checkedRadio} alt="checked" style={{ width: 30 }} />
                      <span style={styles.formHeaderText}>iDeal</span>
                    </div>
                    <div style={{ height: 1, backgroundColor: 'lightGray', marginBottom: 20, marginTop: 10 }} />
                    <div className="flex flex-wrap  flex-col flex justify-center items-center">
                      <span style={{ color: 'lightGray', fontSize: 12 }}>Yuge Technologies B.V</span>
                      <span style={{ color: 'lightGray', fontSize: 12 }}>Dutch Chamber of Commerce nr 64371069</span>
                      <span style={{ color: 'lightGray', fontSize: 12 }}>Postbus 15421, 1001 MK Amsterdam (NL)</span>
                    </div>
                  </div>
                )}
                {currentCartStep === cartSteps.reviewView
                && (
                  <div style={{ width: 570 }}>
                    <span style={{ ...styles.formHeaderText, marginBottom: 10 }}>Shipping info</span>
                    <br />
                    <span style={{ ...styles.formHeaderText, fontSize: 14 }}>Osiel Lima</span>
                    <br />
                    <span style={{ ...styles.formHeaderText, fontSize: 14 }}>11040 Belgrade</span>
                    <br />
                    <span style={{ ...styles.formHeaderText, fontSize: 14 }}>Serbia</span>
                    <div style={{ height: 10 }} />
                    <span style={{ ...styles.formHeaderText, marginBottom: 10 }}>Payment method</span>
                    <br />
                    <span style={{ ...styles.formHeaderText, fontSize: 14 }}>iDeal</span>
                    <br />
                    <div style={{ height: 10 }} />
                    <div style={{ height: 1, backgroundColor: 'lightGray', marginBottom: 10 }} />
                    <div className="flex justify-between items-center">
                      <span style={{ ...styles.formHeaderText, fontSize: 14 }}>items price:</span>
                      <span style={styles.formHeaderText}>€ 40.00</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span style={{ ...styles.formHeaderText, fontSize: 14 }}>delivery:</span>
                      <span style={styles.formHeaderText}>free - € 0.00</span>
                    </div>
                    <div style={{ height: 1, backgroundColor: 'lightGray', marginBottom: 10, marginTop: 10 }} />
                    <div className="flex justify-between items-center">
                      <span style={{ ...styles.formHeaderText, fontSize: 17 }}>Total order price:</span>
                      <span style={{ ...styles.formHeaderText, fontSize: 17 }}>€ 40.00</span>
                    </div>
                    <div style={{ height: 1, backgroundColor: 'lightGray', marginBottom: 20, marginTop: 10 }} />
                    <div className="flex flex-wrap  flex-col flex justify-center items-center">
                      <span style={{ color: 'lightGray', fontSize: 12 }}>Yuge Technologies B.V</span>
                      <span style={{ color: 'lightGray', fontSize: 12 }}>Dutch Chamber of Commerce nr 64371069</span>
                      <span style={{ color: 'lightGray', fontSize: 12 }}>Postbus 15421, 1001 MK Amsterdam (NL)</span>
                    </div>
                  </div>
                )}
                {currentCartStep === cartSteps.finishView
                && (
                  <div style={{ width: 570 }} className="flex justify-center items-center">
                    <div id="buy-result-anim" style={{ width: 250, height: 250 }} />
                  </div>
                )}
              </div>

              <div className="flex justify-end items-center" style={{ marginTop: 20 }}>
                { currentCartStep !== cartSteps.cartView
                && (
                  <Button className="free-trial-btn" onClick={() => { onNextBackCartPressed(false); }} style={styles.buttons}>
                    Back
                  </Button>
                )}
                { currentCartStep !== cartSteps.finishView
                && (
                  <Button className="free-trial-btn" onClick={() => { onNextBackCartPressed(true); }} style={styles.buttons}>
                    {currentCartStep === cartSteps.reviewView ? 'Pay' : 'Next'}
                  </Button>
                )}
              </div>
            </Container>
          </div>
        </Accordion.Collapse>
      </Accordion>
      {showCart && <div style={{ borderBottom: '1px solid #E2E2E2', height: 1, width: '100%' }} />}
      <FreeTrialModal show={show} onClose={handleClose} />
    </>
  );
};
export default memo(NavBar);
