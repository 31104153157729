import axios from 'axios';
import { baseURL } from '../api/yugeAPI2';
import logger from './logService';

axios.defaults.baseURL = baseURL;

axios.interceptors.response.use(null, (error) => {
  const errorResponse = error.response;
  const expectedError = errorResponse && errorResponse.status >= 400 && errorResponse.status < 500;

  if (!expectedError) {
    logger.log(error);
  }

  return Promise.reject(error);
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

function errorCatcher(error) {
  if (axios.isCancel(error)) {
    logger.log(`Request canceled ${error}`);
  } else {
    logger.log(error);
  }
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  cancelSource: axios.CancelToken.source,
  errorCatcher,
};
