import React, { memo } from 'react';
import PropType from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';

const TextImageSection = ({ src, inverted, children, clssName = "md:items-center" }) => {
  const fadeInEffect = !inverted ? "animate__fadeInLeft" : "animate__fadeInRight";
  const fadeOutEffect = !inverted ? "animate__fadeOutLeft" : "animate__fadeOutRight";
  return (
    <div
      className={`relative flex flex-col ${
        inverted ? 'md:flex-row-reverse' : 'md:flex-row'
      } w-full md:justify-between ${clssName} my-12 md:my-20`}
    >
      <div className="md:w-6/12">
        <ScrollAnimation animateIn={fadeInEffect} animateOut={fadeOutEffect}>
          <img className="w-100 rounded" src={src} alt="Imaged" />
        </ScrollAnimation>
      </div>
      <div className="mt-6 md:w-5/12">{children}</div>
    </div>
  );
};

TextImageSection.propTypes = {
  src: PropType.string.isRequired,
  inverted: PropType.bool,
  children: PropType.node,
  clssName: PropType.string,
};

export default memo(TextImageSection);
