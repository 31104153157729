import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import ScrollAnimation from 'react-animate-on-scroll';
import Block from './block';
import 'react-alice-carousel/lib/alice-carousel.css';
import TextImageSection from '../common/textImageSection';

import arrowBlue from '../../assets/img/modelCreation/arrow-blue.svg';
import image1 from '../../assets/img/hosting/image1.png';
import image2 from '../../assets/img/hosting/image2.png';
import loopMask from '../../assets/img/hosting/loop-mask.svg';
import loopMaskMobile from '../../assets/img/hosting/loop-mask-mobile.svg';

const Hosting = () => {
  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      <div className="container px-0 pb-8 mt-6">
        <Block title={<FormattedMessage id="HOSTING" />}>
          <div className="pt-2 pb-2 md:pt-8 md:pb-8" />
          <TextImageSection src={image1} clssName="md:items-end">
            <ScrollAnimation animateIn="animate__fadeIn" animateOut="animate__fadeOut" delay={800}>
              <img
                id="hosting-market"
                className="opacity-0 md:opacity-100"
                src={loopMask}
                alt="loopmask"
              />
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeIn" animateOut="animate__fadeOut" delay={800}>
              <img
                id="hosting-market-mobile"
                className="opacity-100 md:opacity-0 w-64"
                src={loopMaskMobile}
                alt="loopmask"
              />
            </ScrollAnimation>
            <p className="text-lg px-6 md:px-0">
              <FormattedMessage id="HOSTING_1" />
            </p>
          </TextImageSection>
          <TextImageSection inverted src={image2}>
            <p className="text-lg px-6 md:px-0">
              <FormattedMessage id="HOSTING_2" />
            </p>
          </TextImageSection>
          <a
            href="https://www.nijhuis-ar.nl/"
            className="w-full mb-2 -mt-8 flex flex-row pr-6 md:pr-0 justify-end md:justify-center text-blue-400 font-bold"
            style={{ fontSize: '18px' }}
          >
            <FormattedMessage id="VIEW_EXAMPLE" />
            <div>
              <img className="ml-3 mr-0 md:mr-4" src={arrowBlue} alt="view example" />
            </div>
          </a>
        </Block>
      </div>
    </div>
  );
};
export default memo(Hosting);
