/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';

export default function PlanFeatures({ features }) {
  return (
    <div
      role="listitem"
      className="bg-white my-12 relative z-30 w-full md:w-80 font-semibold p-1"
    >
      {features?.map(feature => (
        <div className="text-base flex gap-2 items-center mt-2" key={feature}><FiCheck /> <FormattedMessage id={feature} /></div>
      ))}
    </div>
  );
}
