import React, { memo } from 'react';

// eslint-disable-next-line react/prop-types
const SocialNetwork = ({ classes }) => {
  return (
    <p className={classes}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/YugeApp"
        className="facebook-icon-yuge visual"
      >
        <span className="d-none">Facebook</span>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/yuge_ar"
        className="instagram-icon-yuge visual"
      >
        <span className="d-none">Instagram</span>
      </a>
      <a rel="noopener noreferrer" href="/contact" className="mail-icon-yuge visual">
        <span className="d-none">Contacto</span>
      </a>
    </p>
  );
};

export default memo(SocialNetwork);
