import React from "react";
import { Row, Col } from "react-bootstrap";

const SubTotal = () => {
  return (
    <Row>
      <Col xs={6}>Subtotal (2 products)</Col>
      <Col xs={6} style={{ textAlign: 'right' }}>
        <strong>$100</strong>
      </Col>
    </Row>
  );
};

export default SubTotal;
