import { useEffect, useState } from "react";

export default function ArSupported() {
  const [supported, setSupported] = useState(null);
  const [isMobile, setIsMobile] = useState({
    isAndroid: false,
    isIOS: false,
  });

  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setIsMobile({
        isIOS: false,
        isAndroid: true,
      });
    }
    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      setIsMobile({
        isAndroid: false,
        isIOS: true,
      });
    }
  }, []);

  useEffect(() => {
    const anchor = document.createElement('a');
    // IF iOS and AR supported
    if (anchor.relList.supports('ar')) {
      setSupported(true);
      return;
    }

    const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    if (isMobile.isAndroid && isChrome) {
      setSupported(true);
    } else {
      setSupported(false);
    }
  }, [isMobile]);

  return {
    arSupported: supported,
    ...isMobile,
  };
}
