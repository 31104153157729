import React, { memo, useState, useEffect } from 'react';
import { Button, Modal, Spinner, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import isEmailValid from '../../utils/isEmail';
import { createTrialAccount, setEmailSent, setErrorTrialMessage } from '../../redux/Actions';
import { hasWhiteSpace, isPassWordStrong } from '../../utils/isPasswordStrong';

const FreeTrialModal = (props) => {
  const { loading, emailSent, errorTrialMessage: errorMessage } = useSelector(
    (state) => state.trial,
  );
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [storeName, setStoreName] = useState('');
  const [errorFormMessage, setErrorFormMessage] = useState(null);
  const [showPassRequirements, setShowPassRequirements] = useState(false);
  // eslint-disable-next-line react/prop-types
  const { intl, show, onClose } = props;
  const [data, setData] = useState({
    email: false,
    password: false,
    passwordConfirmation: false,
    storeName: false,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPassRequirements(false);
      dispatch(setErrorTrialMessage(null));
    }, 7000);

    return () => {
      clearTimeout(timer);
    };
  }, [errorMessage, dispatch]);

  const handleOnChangeEmail = (event) => {
    if (event && event.target && event.target.value) {
      setEmail(event.target.value);
      setErrorFormMessage(null);
    } else {
      setEmail('');
    }
  };

  const handleOnChangePassword = (event) => {
    if (event && event.target && event.target.value) {
      setPassword(event.target.value);
      setErrorFormMessage(null);
      setShowPassRequirements(false);
    } else {
      setPassword('');
    }
  };

  const handleOnChangePasswordConfirmation = (event) => {
    if (event && event.target && event.target.value) {
      setPasswordConfirmation(event.target.value);
      setErrorFormMessage(null);
      setShowPassRequirements(false);
    } else {
      setPasswordConfirmation('');
    }
  };

  const handleOnChangeStoreName = (event) => {
    if (event && event.target && event.target.value) {
      setStoreName(event.target.value);
      setErrorFormMessage(null);
    } else {
      setStoreName('');
    }
  };

  const showError = (message) => {
    setErrorFormMessage(message);
    setTimeout(() => {
      setErrorFormMessage(null);
      setShowPassRequirements(false);
    }, 7000);
  };

  const handleCreateStore = () => {
    if (email.trim() === '') {
      return;
    }
    if (!isEmailValid(email)) {
      return;
    }
    if (password.trim() === '') {
      setShowPassRequirements(true);
      return;
    }
    if (!isPassWordStrong(password)) {
      setShowPassRequirements(true);
      return;
    }
    if (hasWhiteSpace(password)) {
      setShowPassRequirements(true);
      return;
    }
    if (password !== passwordConfirmation) {
      return;
    }
    if (storeName.trim() === '') {
      return;
    }
    dispatch(createTrialAccount({ email, password, storeName }));
  };

  const getEmailError = () => {
    if (!data.email) return "";
    let err = 'NONE';
    if (email.trim() === '') {
      err = 'EMAIL_MUST_BE_VALID';
    } else if (!isEmailValid(email)) {
      err = 'EMAIL_MUST_BE_CORRECT_FORMAT';
    }
    return intl.formatMessage({ id: err });
  };

  const getPasswordError = () => {
    if (!data.password) return "";
    let err = 'NONE';
    if (password.trim() === '') {
      err = 'PASSWORD_MUST_BE_VALID';
    } else if (!isPassWordStrong(password)) {
      err = 'PASSWORD_INVALID_REQUIREMENTS';
    } else if (hasWhiteSpace(password)) {
      err = 'PASSWORD_CANNOT_HAVE_WHITESPACES';
    }
    return intl.formatMessage({ id: err });
  };

  const getConfirmPasswordError = () => {
    if (!data.passwordConfirmation) return "";
    if (password !== passwordConfirmation) {
      return intl.formatMessage({ id: 'PASSWORD_CONFIRMATION_NO_MATCH' });
    }
    return '';
  };

  const getStoreError = () => {
    if (!data.storeName) return "";
    if (storeName.trim() === '') {
      return intl.formatMessage({ id: 'STORE_NAME_MUST_BE_VALID' });
    }
    return '';
  };

  const handleChangeEmailButtonClick = () => {
    dispatch(setEmailSent(false));
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      className="fixed inset-0 z-40 overflow-y-auto rounded-lg"
      style={{ zIndex: 9999999 }}
    >
      {!emailSent && (
        <div>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {errorFormMessage && (
            // eslint-disable-next-line react/prop-types
            <Alert variant="danger">{intl.formatMessage({ id: errorFormMessage })}</Alert>
          )}
          {showPassRequirements && (
            // eslint-disable-next-line react/prop-types
            <Alert variant="danger">{intl.formatMessage({ id: 'PASSWORD_REQUIREMENTS' })}</Alert>
          )}
          <Modal.Body>
            {/* eslint-disable-next-line react/prop-types */}
            <label htmlFor="email">{intl.formatMessage({ id: 'EMAIL_ADDRESS' })}</label>
            <input
              id="email"
              required
              type="email"
              className="block appearance-none w-full py-2 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
              value={email}
              onFocus={() => {
                setData({ ...data, email: true });
              }}
              onChange={handleOnChangeEmail}
            />
            <div className="mt-1 text-red-500 text-xs">{getEmailError()}</div>
            <label className="mt-4 mb-1" htmlFor="password">{intl.formatMessage({ id: 'PASSWORD' })}</label>
            <input
              id="password"
              style={{ marginTop: 0 }}
              required
              type="password"
              className="block appearance-none w-full py-2 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
              value={password}
              onFocus={() => {
                setData({ ...data, password: true });
              }}
              onChange={handleOnChangePassword}
            />
            <div className="mt-1 text-red-500 text-xs">{getPasswordError()}</div>
            <label className="mt-4 mb-1" htmlFor="passwordr">{intl.formatMessage({ id: 'PASSWORD_CONFIRMATION' })}</label>
            <input
              id="passwordr"
              style={{ marginTop: 0 }}
              required
              type="password"
              className="block appearance-none w-full py-2 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
              value={passwordConfirmation}
              onFocus={() => {
                setData({ ...data, passwordConfirmation: true });
              }}
              onChange={handleOnChangePasswordConfirmation}
            />
            <div className="mt-1 text-red-500 text-xs">{getConfirmPasswordError()}</div>
            <label className="mt-4 mb-1" htmlFor="store">{intl.formatMessage({ id: 'YOUR_STORE_NAME' })}</label>
            <input
              id="store"
              required
              type="text"
              className="block appearance-none w-full py-2 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
              value={storeName}
              onFocus={() => {
                setData({ ...data, storeName: true });
              }}
              onChange={handleOnChangeStoreName}
            />
            <div className="mt-1 text-red-500 text-xs">{getStoreError()}</div>
          </Modal.Body>
          <Modal.Footer>
            {!loading && (
              <Button className="w-full items-center border-0 py-3 px-9 shadow-lg focus:outline-none rounded font-semibold disabled:opacity-50 text-yellow-800" onClick={handleCreateStore}>
                {
                  // eslint-disable-next-line react/prop-types
                    intl.formatMessage({ id: 'CREATE_YOUR_STORE' }).toString()
                }
              </Button>
            )}
            <div className="w-full flex flex-row justify-center items-center">
              {loading && <Spinner animation="border" role="status" className="free-trial-spinner" />}
            </div>
          </Modal.Footer>
        </div>
      )}
      {emailSent && (
        <div>
          <Modal.Body>
            <p className="text-xl">
              {
                // eslint-disable-next-line react/prop-types
                intl.formatMessage({ id: 'EMAIL_CONFIRMATION' })
              }
            </p>
            <p className="text-lg">
              {
                // eslint-disable-next-line react/prop-types
                `${intl.formatMessage({ id: 'YUGE_SENT_EMAIL' }) + email}. ${intl.formatMessage({ id: 'OPEN_EMAIL_AND_CONFIRM' })}`
              }
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="w-full items-center border-0 py-3 px-9 shadow-lg focus:outline-none rounded font-semibold disabled:opacity-50 text-yellow-800" onClick={handleChangeEmailButtonClick}>
              {
                // eslint-disable-next-line react/prop-types
                 intl.formatMessage({ id: 'CHANGE_EMAIL' }).toString()
              }
            </Button>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
};

export default memo(injectIntl(FreeTrialModal));
