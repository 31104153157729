import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import TrustPilotWidget from './trustPilotWidget';

const LegalNote = () => {
  return (
    <>
      <p>
        <a
          href="https://terms-of-service.goyuge.com"
          className="uppercase text-gray-600 text-base text-decoration-none hover:text-gray-600"
        >
          <FormattedMessage id="TERMS" />
        </a>
        <a
          href="https://privacy-policy.goyuge.com"
          className="uppercase text-gray-600 text-base text-decoration-none hover:text-gray-600"
        >
          <FormattedMessage id="PRIVACY" />
        </a>
        <a
          href="/pricing#faqs"
          className="uppercase text-gray-600 text-base text-decoration-none hover:text-gray-600"
        >
          FAQ
        </a>
      </p>
      <p className="mt-10 w-full flex justify-center items-center">
        <a
          href="https://stats.uptimerobot.com/0BWw7fkqNG"
          className="uppercase text-gray-600 text-base text-decoration-none hover:text-gray-600"
        >
          <FormattedMessage id="STATUS_PAGE" />
        </a>
        <div className="dot is-big" />
      </p>
      <div className="mt-8">
        <TrustPilotWidget />
      </div>
      <p className="mt-6 footer-legal text-gray-600 text-base">
        <FormattedMessage id="LEGAL_NOTE_1" />
        <br />
        <FormattedMessage id="LEGAL_NOTE_2" />
        {new Date().getFullYear()}
      </p>
      <div className="footer-bottom">
        <p className="footer-freebird">
          <FormattedMessage id="FUNDED_BY" />
          <a
            href="http://www.freebirdinvest.com"
            style={{ textDecoration: 'none' }}
            className="m-0 p-0"
          >
            <b>Studio Freebird</b>
          </a>
        </p>
      </div>
    </>
  );
};

export default memo(LegalNote);
