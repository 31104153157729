import React, { memo } from 'react';
import PropType from 'prop-types';
import CountUp from 'react-countup';

function PricingCountUp({ price, startFrom }) {
  return (
    <CountUp start={startFrom} end={price} duration={1} />
  );
}

PricingCountUp.propTypes = {
  price: PropType.number,
  startFrom: PropType.number,
};

export default memo(PricingCountUp);
