import http from './httpService';
import region from './regionService';
import { LIVE } from '../api/yugeAPI2';

export const categoriesKey = {
  RECOMMENDED: 'RECOMMENDED',
  WEB_SHOPS: 'WEB_SHOPS',
  ART_DESIGN: 'ART_DESIGN',
  HOME_FURNITURE: 'HOME_FURNITURE',
  DESIGN: 'DESIGN',
};

export function getCategories(config) {
  const data = {
    live: LIVE,
    filter: {
      region: region.code,
      published: true,
      sortByOrder: true,
    },
  };
  return http.post('/categories/search', data, config);
}

export const recommendedCategory = {
  title: {
    en: 'Recommended',
    fr: 'Recommandé',
    nl: 'Aanbevolen',
    ca: 'Recomanat',
    es: 'Recomendado',
    fi: 'Suositeltava',
    ru: 'Pекомендуемые',
  },
  key: categoriesKey.RECOMMENDED,
  images: [null, 'https://app-images.goyuge.com/categories/recommended_selected.png'],
};

// export const webShopsCategory = {
//   title: {
//     en: 'Webshops',
//     fr: 'Webshops',
//     nl: 'Webshops',
//     ca: 'Webshops',
//     es: 'Tiendas online',
//     fi: 'Webshops',
//     ru: 'Webshops',
//   },
//   key: categoriesKey.WEB_SHOPS,
//   images: [null, 'https://app-images.goyuge.com/categories/recommended_selected.png'],
// };
