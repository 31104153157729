import React, { memo, useState, useCallback, Suspense, lazy, useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import isBetween from 'dayjs/plugin/isBetween';
import NavBar from './components/common/navBar';
import Technology from './components/technology';
import Footer from './components/footer';
import language from './services/language/languageService';
import './assets/sass/screen.scss';
import { setUserAction } from './redux/Actions';
import Pricing from './components/pricing';
import ProductPage from './components/product';

dayjs.extend(objectSupport);
dayjs.extend(isBetween);

const Shop = lazy(() => import('./components/shops'));
const AppInfo = lazy(() => import('./components/appInfo'));
const Apps = lazy(() => import('./components/apps'));
const ContactForm = lazy(() => import('./components/contactForm'));
const Merchant = lazy(() => import('./components/merchant'));
const NotFound = lazy(() => import('./components/notFound'));

const App = (props) => {
  const dispatch = useDispatch();
  const { location } = props;
  const [locale, setLocale] = useState(language.defaultLocale);
  // eslint-disable-next-line no-unused-vars
  const [messages, setMessages] = useState(language.getMessages(language.defaultLocale));
  const [currentPath, setCurrentPath] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [drawerState, setDrawerState] = useState('close');
  const [logoKey, setLogoKey] = useState(1);

  const changeLanguage = useCallback((_locale) => {
    const msg = language.getMessages(_locale);
    setLocale(_locale);
    setMessages(msg);
  }, [locale, messages]);

  const handleOnClickEvent = useCallback(() => {
    const tempLogoKey = logoKey === 1 ? 2 : 1;
    setLogoKey(tempLogoKey);
  }, [logoKey]);

  const handlePathChange = useCallback((_currentPath) => {
    setCurrentPath(_currentPath);
  }, []);

  const handleOnDrawerStateChange = useCallback((_drawerState) => {
    setDrawerState(_drawerState);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleLanguageChange = useCallback(
    (_locale) => {
      changeLanguage(_locale);
    },
    [changeLanguage],
  );

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const username = localStorage.getItem('username');
    dispatch(setUserAction({ access_token: accessToken, username }));
  }, []);

  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <IntlProvider key={locale} locale={locale} messages={messages}>
        <>
          <ToastContainer />
          <NavBar
            selectedLanguage={locale}
            onLanguageChange={handleLanguageChange}
            currentPath={currentPath}
            onDrawerStateChange={handleOnDrawerStateChange}
            logokey={logoKey}
          />
          <Suspense fallback={() => 'loading'}>
            <Switch>
              <Route exact path="/">
                <Technology
                  locale={locale}
                  onMount={handlePathChange}
                  onClickEvent={handleOnClickEvent}
                />
              </Route>
              <Route exact path="/shops">
                <Shop
                  selectedLanguage={locale}
                  onMount={handlePathChange}
                  onClickEvent={handleOnClickEvent}
                />
              </Route>
              <Route exact path="/app">
                <AppInfo onMount={handlePathChange} />
              </Route>
              <Route exact path="/apps">
                <Apps onMount={handlePathChange} />
              </Route>
              <Route path="/contact">
                <ContactForm selectedLanguage={locale} onMount={handlePathChange} />
              </Route>
              <Route exact path="/product/:productId">
                <ProductPage selectedLanguage={locale} onMount={handlePathChange} />
              </Route>
              <Route path="/Pricing">
                <Pricing selectedLanguage={locale} onMount={handlePathChange} />
              </Route>
              <Route exact path="/shops/:slug">
                <Merchant drawerState={drawerState} onMount={handlePathChange} />
              </Route>
              <Redirect from="/confirm_by_email" to={{ ...location, search: `${location.search}&action=CONFIRM_EMAIL`, pathname: location.pathname.replace('/confirm_by_email', '/shops') }} />
              <Route path="/not-found">
                <NotFound />
              </Route>
              <Redirect to="/not-found" />
            </Switch>
          </Suspense>
          <Footer selectedLanguage={locale} onLanguageChange={handleLanguageChange} />
        </>
      </IntlProvider>
    </div>
  );
};
export default withRouter(memo(App));
