import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import comment from '../../assets/img/referrals/commentbubble.png';
import profile from '../../assets/img/referrals/profile.png';

const ReferralCard = ({ name, position, company, valoration }) => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center py-12 md:py-24">
      <div className="w-1/2 mb-4 md:mb-0 md:w-1/3 flex justify-center md:mr-8">
        <img src={profile} alt="profile" />
      </div>
      <div className="w-3/4 md:w-3/5">
        <div className="relative">
          <img className="d-none d-md-block w-full" src={comment} alt="comment bubble" />
          <span style={{ height: "68%" }} className="relative md:absolute top-0 md:px-8 left-0 w-full flex justify-center items-center"><FormattedMessage id={valoration} /></span>
        </div>
        <div className="mt-6 md:mt-16 sm:px-auto">
          <p className="text-lg">{name} <br /> {position} <br /> <span className="font-semibold"> {company} </span></p>
        </div>
      </div>
    </div>
  );
};

ReferralCard.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  company: PropTypes.string,
  valoration: PropTypes.string,
};

export default memo(ReferralCard);
