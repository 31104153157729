import React, { memo } from 'react';

// eslint-disable-next-line react/prop-types
const HamburgerButton = ({ onClickHamburger, onMouseOverHamburger }) => {
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <button
      className="hamburger hamburger--collapse"
      type="button"
      style={{ padding: 0 }}
      onClick={(e) => onClickHamburger(e.currentTarget.classList)}
      onMouseOver={onMouseOverHamburger}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};

export default memo(HamburgerButton);
