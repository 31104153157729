import React from "react";
import PropType from 'prop-types';

function SimpleModelViewer({ src, arIOSSupported, arImg, handleiOSARClick, w = '100%', h = 300 }) {
  return (
    <>
      <model-viewer
        style={{ width: w, height: h }}
        auto-rotate
        camera-controls
        ar
        rotation-per-second="30deg"
        alt="A 3D model of an astronaut"
        src={src}
      />
      {
        arIOSSupported && (
          <div
            onClick={handleiOSARClick}
          >
            <img
              src={arImg}
              style={{
                height: 50,
              }}
              alt=""
            />
          </div>
        )
      }
    </>
  );
}

SimpleModelViewer.propTypes = {
  src: PropType.string.isRequired,
  arIOSSupported: PropType.bool,
  handleiOSARClick: PropType.func.isRequired,
  arImg: PropType.string.isRequired,
  w: PropType.string,
  h: PropType.string,
};

export default SimpleModelViewer;
