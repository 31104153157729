import React from "react";
import PropType from 'prop-types';
import { FormattedMessage } from "react-intl";

function PricingHeader({ period, handlePeriod }) {
  return (
    <div className="lg:w-1/2 w-full">
      {/* <h1 className="font-semibold text-center text-gray-600" style={{ fontSize: "40px" }}>
        <FormattedMessage id="PRICING_PLAN" />
      </h1>
      <p
        role="contentinfo"
        className="font-semibold text-center mt-4 text-gray-500 mr-0"
      >
        <FormattedMessage id="PRICING_DESCRIPTION" />
      </p> */}
      <div className="flex justify-center mb-5 sm:mb-0">
        <div className="w-56">
          <button
            type="button"
            style={{ border: "1px solid transparent !important", outline: "none !important" }}
            className="focus:ring-0 border-0 bg-gray-100 shadow rounded-full flex items-center rounded-full"
            onClick={handlePeriod}
          >
            <div
              className={`${period === "monthly" ? "bg-green-300" : "bg-gray-100 text-gray-600"} font-semibold text-base leading-none rounded-xl py-3 px-6 mr-1`}
              id="monthly"
            >
              <FormattedMessage id="MONTHLY" />
            </div>
            <div
              className={`${period === "annually" ? "bg-green-300" : "bg-gray-100 text-gray-600"} font-semibold text-base leading-none rounded-xl py-3 px-6`}
              id="annually"
            >
              <FormattedMessage id="ANNUALLY" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

PricingHeader.propTypes = {
  period: PropType.string,
  handlePeriod: PropType.func,
};

export default PricingHeader;
