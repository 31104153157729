/**
 * Go to augmented reality
 * @param product, the product object
 * @param ARNotSupportedFallback, if AR is not supported this function will be called
 */
export const goToAR = (product, ARNotSupportedFallback) => {
  const {
    price: priceInCents,
    currency,
    title: fullTitle,
    glbFiles,
    usdzFiles,
    externalBuyUrl,
    externalBuyEnabled,
    id,
  } = product;
  const anchor = document.getElementById('ar-link');

  const title = fullTitle ? `${fullTitle.slice(0, 37)}...` : null;

  // Check for iOS and Safari compatibility
  if (anchor.relList.supports('ar') && usdzFiles && usdzFiles.length > 0) {
    let customBannerTitle = '-';
    let customBannerPrice = '-';
    const customBannerActionText = 'BUY';
    let customDomain = 'AR by yuge.nl';

    let usdzUrl = `${usdzFiles[0].URL}#allowsContentScaling=1`;

    if (title && title.trim() !== '') {
      const trimmedTitle = title.trim();
      customBannerTitle = trimmedTitle.replaceAll(' ', '__SPACE__');
      usdzUrl = `${usdzUrl}&checkoutTitle=${trimmedTitle}`; // title to use in case "custom banner" url fails.
    } else {
      usdzUrl = `${usdzUrl}&checkoutTitle=-`; // title to use in case "custom banner" url fails.
    }

    if (priceInCents && priceInCents.trim() !== '' && currency && currency.trim() !== '') {
      const priceInCurrency = priceInCents.trim();
      usdzUrl = `${usdzUrl}&price=${currency.trim()}${priceInCurrency}`; // price and currency to use in case "custom banner" url fails.
      if (currency.trim() === '€') {
        customBannerPrice = `_EUR_${priceInCurrency}`;
      } else {
        customBannerPrice = `${currency.trim()}${priceInCurrency}`;
      }
    }

    if (externalBuyEnabled && externalBuyUrl && externalBuyUrl.trim() !== '') {
      customDomain = externalBuyUrl.split('/')[2];
    }

    const trimmedDomain = customDomain.trim();
    customDomain = trimmedDomain.replaceAll(' ', '__SPACE__');

    usdzUrl = `${usdzUrl}&callToAction=${customBannerActionText}`; // Text to show for blue button in case "custom banner" url fails.
    usdzUrl = `${usdzUrl}&canonicalWebPageURL=${window.location.protocol}//${window.location.host}/product/${id}?noAR=1`; // The action will trigger link to product page without AR

    usdzUrl = `${usdzFiles[0].URL}#allowsContentScaling=1&custom=https://dev-banner.goyuge.com/${customBannerTitle}/${customBannerPrice}/${customBannerActionText}/${customDomain}`;

    usdzUrl = `${usdzUrl}&customHeight=medium`; // height of the custom banner (small, medium, large)

    anchor.setAttribute('href', usdzUrl);
    anchor.addEventListener(
      'message',
      (event) => {
        if (externalBuyEnabled && externalBuyUrl && externalBuyUrl.trim() !== '') {
          window.location.href = externalBuyUrl.trim().replaceAll(' ', '%20');
        } else if (typeof window !== 'undefined') {
          window.location.href = `${window.location.protocol}//${window.location.host}/product/${id}?noAR=1`;
        }
      },
      false,
    );

    anchor.click();
    return;
  }

  // Check for android and Google Chrome compatibility
  const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  if (/Android/i.test(navigator.userAgent) && isChrome && glbFiles && glbFiles.length > 0) {
    let glbURL = `intent://arvr.google.com/scene-viewer/1.0?file=${glbFiles[0].URL}`;
    glbURL = `${glbURL}&mode=ar_only`;

    // When set to true, users will be able to place the model on a vertical surface.
    glbURL = `${glbURL}&enable_vertical_placement=true`;

    const text = `title: ${title || ''}`;
    if (text && text.trim() !== '') {
      glbURL = `${glbURL}&title=${text.trim().replaceAll(' ', '%20')}`;
    } else {
      glbURL = `${glbURL}&title=-`;
    }

    const domainUrl = `${window.location.protocol}//${window.location.host}`;
    if (externalBuyEnabled && externalBuyUrl && externalBuyUrl.trim() !== '') {
      glbURL = `${glbURL}&link=${externalBuyUrl.trim().replaceAll(' ', '%20')}`;
    } else {
      glbURL = `${glbURL}&link=${domainUrl}/product/${id}?noAR=1`;
    }
    glbURL = `${glbURL}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${domainUrl};end;`;
    anchor.setAttribute('href', glbURL);
    anchor.click();
    return;
  }

  // No AR supported, then go to product details
  if (ARNotSupportedFallback) ARNotSupportedFallback();
};
