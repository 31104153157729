import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Logo from '../logo';
import Menu from './menu';
import HamburgerButton from './hamburgerButton';
import CartButton from './cartButton';
import { selectUser } from '../../redux/Selectors';
import HeaderLanguagePicker from './HeaderLanguagePicker';

const NavBarHeader = (props) => {
  const {
    logoClassName,
    classes,
    logoKey,
    menuItems,
    onClickEvent,
    onClickHamburger,
    onMouseOverHamburger,
    selectedLanguage,
    onLanguageChange,
  } = props;
  const user = useSelector(selectUser);
  return (
    <div className={`${classes} border`}>
      <header
        className="md:container md:mx-auto px-2 w-full flex gap-6 flex-row justify-between items-center"
        style={{ width: '100% !important' }}
      >
        <div className="w-1/3">
          <div className="mx-auto">
            <HeaderLanguagePicker
              selectedLanguage={selectedLanguage}
              onLanguageChange={onLanguageChange}
            />
          </div>
        </div>
        <h1 className="m-0 p-0 w-1/3 text-center">
          <a className="link-home" href="/">
            <Logo logoClassName={logoClassName} key={logoKey} />
          </a>
        </h1>
        <div className="header-menu min-h-full flex flex-row items-center justify-end w-1/3">
          <Menu menuItems={menuItems} onClickEvent={onClickEvent} />
          {user &&
            user.access_token &&
            user.access_token !== 'undefined' &&
            user.access_token !== '' &&
            false && <CartButton />}{' '}
          {/* TODO: false to go to production with a middle solution */}
          <HamburgerButton
            onClickHamburger={onClickHamburger}
            onMouseOverHamburger={onMouseOverHamburger}
          />
        </div>
      </header>
    </div>
  );
};

export default memo(NavBarHeader);
